.about {
  flex-direction: column;
  margin-top: 3em;
}

.about__name {
  color: var(--clr-primary);
  /* color: rgb(3, 3, 9); */
}

.about__role {
  margin-top: 1.2em;
  font-size: 1.25rem;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc{
  margin-top: 2.4em;
}

.about__contact {
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
  size: 5em;
}

.about .btn--outline {
  margin-right: 1em;
}

.image-position {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
  height: auto;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}
